<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title> Update Problem </v-card-title>
      <v-card-text>
        <div v-if="!ready">
          <h2>Data is loading...</h2>
        </div>
        <div v-else>
          <v-text-field
            label="Problem Description"
            v-model="form.problem_description"
          ></v-text-field>

          <!-- ACTIONS PERFORMED -->
          <div class="">
            <p class="warning--text">ACTIONS</p>
            <ol>
              <li v-for="action in form.actions_performed" :key="action">
                {{ action }}

                <v-btn
                  icon
                  small
                  color="red"
                  @click="delete_action(action, 'act')"
                  ><v-icon>mdi-minus-thick</v-icon></v-btn
                >
              </li>
            </ol>
            <v-row>
              <v-col>
                <v-text-field
                  class="mt-2"
                  label="Action Performed"
                  v-model="action"
                  prepend-icon="mdi-hammer-screwdriver"
                ></v-text-field
              ></v-col>
              <v-col cols="2">
                <v-btn small @click="insert_item('act')" icon>
                  <v-icon>mdi-plus</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </div>

          <!-- OTHER REMARKS -->
          <div class="mt-2">
            <p class="primary--text">REMARKS</p>
            <ol>
              <li v-for="remarks in form.other_remarks" :key="remarks">
                {{ remarks }}

                <v-btn
                  icon
                  small
                  color="red"
                  @click="delete_action(remarks, 'rem')"
                  ><v-icon>mdi-minus-thick</v-icon></v-btn
                >
              </li>
            </ol>
            <v-row>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-doctor"
                  class="mt-2"
                  label="remarks"
                  v-model="remarks"
                ></v-text-field
              ></v-col>
              <v-col cols="2">
                <v-btn small @click="insert_item('rem')" icon class="mt-5">
                  <v-icon>mdi-plus</v-icon>
                </v-btn></v-col
              >
            </v-row>
          </div>
          <!-- END -->

          <v-text-field
            label="Technician"
            v-model="form.technician"
            prepend-icon="mdi-account-hard-hat-outline"
          ></v-text-field>

          <p>Repair Initialized</p>
          <input
            type="date"
            v-model="form.repair_initialized"
            class=""
            color="white"
          />
          <v-select
            label="Status"
            v-model="form.status"
            :items="status"
          ></v-select>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="update_this_problem"
          :loading="button_loading"
          >Update</v-btn
        >
        <v-btn @click="close" text>Close</v-btn>

        <v-spacer></v-spacer>
        <p class="caption grey--text">updateProblem.vue</p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      button_loading: false,
      ready: false,
      dialog: true,
      status: ["Pending", "On-going", "Fixed", "Cancelled" ],
      action: "",
      recommendation: "",
      remarks: "",
      form: {
        id: this.item.id,
        customer_id: this.item.customer_id,
        unit_id: this.item.unit_id,
        problem_description: this.item.problem_description,
        status: this.item.status,
        actions_performed: JSON.parse(this.item.actions_performed),
        other_remarks: JSON.parse(this.item.other_remarks),
        recommendations: JSON.parse(this.item.recommendations),
        technician: this.item.technician,
        repair_initialized: this.item.repair_initialized,
      },
    };
  },
  created() {
    this.ready = false;
    if (this.users.length <= 0)
      this.get_users().then(() => {
        this.ready = true;
      });
    this.ready = true;
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      users: "auth/users",
    }),
  },
  methods: {
    ...mapActions({
      get_users: "auth/get_users",
      update_problem: "problem/update_problem",
      set_notification: "notification/set_notification",
      get_all_units: "unit/get_all_units",
    }),
    close() {
      this.$emit("close");
    },
    delete_action(item, type) {
      if (type == "act") {
        var act_arr = this.remove_item(item, "act");
        this.form.actions_performed = act_arr;
      } else if (type == "rec") {
        var rec_arr = this.remove_item(item, "rec");
        this.form.recommendations = rec_arr;
      } else {
        var rem_arr = this.remove_item(item, "rem");
        this.form.other_remarks = rem_arr;
      }
    },

    delete_recommendation(recommendation) {
      var new_arr = this.remove_item(recommendation, "rec");
      this.form.recommendations = new_arr;
    },
    remove_item(item, type) {
      if (type == "act") {
        let filtered = this.form.actions_performed.filter((c) => {
          return c !== item;
        });
        return filtered;
      } else if (type == "rec") {
        let filtered = this.form.recommendations.filter((c) => {
          return c !== item;
        });
        return filtered;
      } else {
        let filtered = this.form.other_remarks.filter((c) => {
          return c !== item;
        });
        return filtered;
      }
    },
    async update_this_problem() {
      this.button_loading = true;
      await this.update_problem(this.form);
      this.button_loading = false;
      // get units
      this.get_all_units();
      return this.set_notification({
        message: "Successfully updated a problem!",
        type: "success",
      });
    },
    insert_item(type) {
      let u = this.user.username;
      if (type == "act") {
        this.form.actions_performed.push(
          this.action.toUpperCase() +
            " by: " +
            u.toUpperCase() +
            " - " +
            moment().format("MMMM-D-YYYY")
        );
        this.action = "";
      } else if (type == "rec") {
        this.form.recommendations.push(
          this.recommendation.toUpperCase() +
            " by: " +
            u.toUpperCase() +
            " - " +
            moment().format("MMMM-D-YYYY")
        );
        this.recommendation = "";
      } else {
        this.form.other_remarks.push(
          this.remarks.toUpperCase() +
            " by: " +
            u.toUpperCase() +
            " - " +
            moment().format("MMMM-D-YYYY")
        );
        this.remarks = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
